import React from 'react'

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon
} from 'react-share'

const ShareButtons = ({ title, url, locale }) => {
  const twitterHandle = "FRENDSplatform";
  const tags = ["frends", "ipaas", "eipaas", "rpa", "hyperautomation"];

  const isLocaleDe = () => locale === 'de';

  const getTranslation = (key) => {
    if (key === 'share') {
      return isLocaleDe() ? 'teilen' : 'share';
    }
    return key;
  };

  return (
    <div className="margin-top-70">
      <h3 className="size-18">
        {getTranslation('share')}
      </h3>
      <FacebookShareButton url={url} >
        <FacebookIcon size={40} round={true} />
      </FacebookShareButton>

      <TwitterShareButton className="margin-x-10" url={url} title={title} via={twitterHandle} hashtags={tags}>
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton>

      <LinkedinShareButton url={url} >
        <LinkedinIcon size={40} round={true} />
      </LinkedinShareButton>
    </div>
  )

}
export default ShareButtons