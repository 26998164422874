import React, { useRef, useEffect } from "react";

const ContentHTMLWithHoverPopUps = ({ contentHTML, popUpItems, className }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        if (!containerRef.current || !(popUpItems && popUpItems.length > 0)) {
            return;
        }

        const linksWithPopUp = [];
        const contentLinks = [...containerRef.current.querySelectorAll('a')];
        contentLinks.forEach((l) => {
            const foundItem = popUpItems.find((i) => l.href.includes(i.slug));
            if (foundItem !== undefined) {
                l.dataset.popupTitle = foundItem.title;
                l.dataset.popupContent = foundItem.content;
                l.dataset.popupSlug = foundItem.slug;
                linksWithPopUp.push(l);
            }
        });

        const openHoverPopUp = (event, link) => {
            const hoveredEl = event.target;
            if (window.innerWidth < 1360 || hoveredEl !== link || link.querySelector('.popup')) {
                return;
            }

            hoveredEl.style.position = 'relative';

            const popUpArticle = document.createElement('article');
            popUpArticle.classList.add('popup');

            const articleHeading = document.createElement('h2');
            articleHeading.innerText = hoveredEl.dataset.popupTitle;
            popUpArticle.appendChild(articleHeading);

            const articleContent = document.createElement('div');
            articleContent.innerHTML = hoveredEl.dataset.popupContent;
            popUpArticle.appendChild(articleContent);

            hoveredEl.appendChild(popUpArticle);
        }

        const closeHoverPopUp = (event, link) => {
            const hoveredEl = event.target;

            if (hoveredEl !== link || document.activeElement === link) {
                return;
            }

            const hoverPopUp = hoveredEl.querySelector('.popup');
            if (hoverPopUp) {
                hoverPopUp.remove();
                hoveredEl.style.position = 'unset';
            }
        }

        linksWithPopUp.forEach((l) => {
            l.classList.add('content-link-hover-with-popup');
            l.addEventListener('mouseover', (e) => openHoverPopUp(e, l));
            l.addEventListener('focusin', (e) => openHoverPopUp(e, l));
            l.addEventListener('mouseout', (e) => closeHoverPopUp(e, l));
            l.addEventListener('focusout', (e) => closeHoverPopUp(e, l));
        });
    }, [containerRef]);

    return (
        <div ref={containerRef} className={className} dangerouslySetInnerHTML={{ __html: contentHTML }} />
    )
}

export default ContentHTMLWithHoverPopUps;
