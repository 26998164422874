import { graphql, useStaticQuery } from "gatsby";
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { slug, useLocalizeHref } from "../helpers";
import { Button } from "./Button";

export const PageLinkList = ({ lists, header, locale }) => {

    const query = useStaticQuery(graphql`query PageLinkListQuery {
        bg2: file(relativePath: {eq: "images/new_purple_background2.png"}) {
            childImageSharp {
                gatsbyImageData(height: 400, width: 400, layout: FIXED)
            }
        }
    }`);

    const isLocaleDe = () => locale === 'de';
    const getLocalizedHref = useLocalizeHref(locale);

    if (lists === null) {
        return null;
    }

    const resolveLinkAndName = (link) => {
        let path = "";
        let title = "";
        let image = "";
        let description = "";
        let outerLink = false;
        switch (link.__typename) {
            case "ContentfulCustomer":
                path = getLocalizedHref(link.slug !== null ? `/customers/${link.slug}` : `/customers/${slug(link.name)}`);
                title = link.name;
                image = link.heroImage;
                description = link.description;
                break;
            case "ContentfulArticle":
                path = getLocalizedHref(link.articleUrl !== null ? `/article/${link.articleUrl}` : `/article/${slug(link.title)}`);
                title = link.title;
                image = link.heroImage;
                description = link.subtitle;
                break;
            case "ContentfulCoSellPartner":
                path = link.slug !== null ? `/partner/co-sell/${link.slug}` : `/partner/co-sell/${slug(link.name)}`;
                title = link.name;
                image = "";
                description = link.slogan;
                outerLink = isLocaleDe();
                break;
            case "ContentfulCustomerType":
                path = link.slug !== null ? `/industries/${link.slug}` : `/industries/${slug(link.name)}`;
                title = link.name;
                image = link.heroImage;
                description = `Read more about ${link.name}`;
                outerLink = isLocaleDe();
                break;
            case "ContentfulServicesSubpage":
                path = link.slug !== null ? `/services/${link.slug}` : `/services/${slug(link.title)}`;
                title = link.title;
                image = link.heroImage;
                description = link.ingress.childMarkdownRemark.excerpt;
                outerLink = isLocaleDe();
                break;
            case "ContentfulLanding":
                path = getLocalizedHref(link.slug !== null ? `/landing/${link.slug}` : `/landing/${slug(link.title)}`);
                title = link.title;
                image = link.heroImage;
                description = link.ingress.childMarkdownRemark.excerpt;
                break;
            case "ContentfulWebinarV2":
                path = link.webinarUrl !== null ? `/webinar/${link.webinarUrl}` : `/webinar/${slug(link.title)}`;
                title = link.title;
                image = link.heroImage;
                description = link.webinarFreeText !== null ? link.webinarFreeText.childMarkdownRemark.excerpt : "Read more";
                outerLink = isLocaleDe();
                break;
            case "ContentfulAcademy":
                path = '/services/frends-academy';
                title = undefined;
                image = "";
                description = undefined;
                outerLink = isLocaleDe();
                break;
            default:
                break;
        }
        return (
            <div className="columns is-mobile is-multiline is-vcentered">
                <div className="column is-12 link-image">
                    {image ? <GatsbyImage image={getImage(image)} style={{ width: "100%", borderRadius: "15px" }} alt="" /> : <GatsbyImage
                        image={getImage(query.bg2)}
                        style={{ width: "100%", borderRadius: "15px" }}
                        alt=""
                    />}
                </div>
                <div className="column is-12">
                    <h2>{title}</h2>
                    <p>{description}</p>
                    <Button
                        type="outlined"
                        text={isLocaleDe() ? 'Lesen Sie weiter' : 'read more'}
                        color="purple"
                        to={path}
                        outerLink={outerLink}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="link-lists-container">
            {header !== null && header !== undefined && (
                <h2>{header}</h2>
            )}
            {lists !== undefined && lists.map((list, i) => (
                <React.Fragment key={i}>
                    <h2>{list.title}</h2>
                    <div className="columns is-mobile is-multiline">
                        {list.linkList !== undefined && list.linkList.map((link, x) => (
                            <div key={x} className="column is-6-desktop is-6-tablet is-12-mobile">
                                <div className="customer-content">
                                    {resolveLinkAndName(link)}
                                </div>
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}