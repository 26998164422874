import React from "react";
import { graphql, Link } from "gatsby";
import { isEmpty } from "lodash-es";

import Layout from "../components/layout/Layout";
import { slug, adjustImgWidthAndHeight, getDefinedTermPopUpItem, getShownItemsForLocale } from "../helpers";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";

import blockquote_start from "../assets/images/blockquote_start.png";
import blockquote_end from "../assets/images/blockquote_end.png";

import grey_outline_circle from "../assets/images/grey_outline_circle.svg";
import grey_outline_rectangle from "../assets/images/grey_outline_rectangle.svg";
import grey_outline_square from "../assets/images/grey_outline_square.svg";
import grey_outline_cylinder from "../assets/images/grey_outline_cylinder.svg";
import grey_outline_transcript from "../assets/images/grey_outline_transcript.svg";
import small_transcript_green from "../assets/images/small_transcript_green.svg";
import small_transcript_orange from "../assets/images/small_transcript_orange.svg";
import small_transcript_purple from "../assets/images/small_transcript_purple.svg";
import small_transcript_pink from "../assets/images/small_transcript_pink.svg";
import small_transcript_blue from "../assets/images/small_transcript_blue.svg";
import grey_play from "../assets/images/grey_play.svg";
import { Button, ButtonIconHover } from "../components/Button";
import ShareButtons from "../components/ShareButtons";
import { PageLinkList } from "../components/PageLinkList";
import ContentHTMLWithHoverPopUps from "../components/ContentHTMLWithHoverPopUps";

export default ({ data, location, pageContext }) => {
    const {
        results,
        businessGoals,
        quoteAuthor,
        quote,
        numbers,
        name,
        whitePaper,
        videoLink,
        heroImage,
        integrationPatterns,
        integrationChallenges,
        itLandscape,
        image,
        logo,
        description,
        category,
        metaTags,
        linkedInThumbnail,
        pageLinkLists,
        pageLinkListHeader
    } = data.contentfulCustomer;


    const numbersIcons = [
        grey_outline_square,
        grey_outline_circle,
        grey_outline_cylinder,
        grey_outline_rectangle,
        grey_outline_transcript
    ];

    const seoData = {
        metaTags: metaTags,
        image: linkedInThumbnail !== null ? linkedInThumbnail?.file?.url : heroImage?.file?.url
    }

    const transcriptIcons = [
        { name: "green", image: small_transcript_green },
        { name: "orange", image: small_transcript_orange },
        { name: "purple", image: small_transcript_purple },
        { name: "pink", image: small_transcript_pink },
        { name: "blue", image: small_transcript_blue }
    ];

    const color = data.contentfulCustomer.color.toLowerCase();
    const siteColors = Object.entries(data.site.siteMetadata.colors);
    const blockQuoteColor = siteColors.find((x) => x[0] === color)[1];
    const transcriptIcon = transcriptIcons.find((f) => f.name === color).image;

    const moreCustomers = getShownItemsForLocale(data.allContentfulCustomer.edges, pageContext.locale);
    const moreCustomersColors = ["green", "orange", "purple", "pink", "blue"];

    const getColor = (i) => moreCustomersColors[i] ? moreCustomersColors[i] : moreCustomersColors[Math.floor(Math.random() * moreCustomersColors.length)];

    const logoAlt = logo.title;
    const adjustedLogoWidthAndHeight = adjustImgWidthAndHeight(logo.file.details.image.width, 100, logo.file.details.image.height, 50);

    const definedTermsPopUpItems = data.allContentfulDefinedTerm.nodes.map(getDefinedTermPopUpItem);

    const isLocaleDe = () => pageContext.locale === 'de';

    const getTranslation = (key) => {
        if (key === 'title') {
            return isLocaleDe() ? 'Kunde' : 'Customer';
        }
        if (key === 'whitePaper') {
            return isLocaleDe() ? 'Whitepaper' : 'whitepaper';
        }
        if (key === 'watchVideo') {
            return isLocaleDe() ? 'Zum Video' : 'watch video';
        }
        if (key === 'itLandscape') {
            return isLocaleDe() ? 'IT-Landschaft' : 'IT Landscape';
        }
        if (key === 'integrationPatterns') {
            return isLocaleDe() ? 'Integrationsmuster' : 'Integration patterns';
        }
        if (key === 'businessGoals') {
            return isLocaleDe() ? 'Geschäftsziele' : 'Business Goals';
        }
        if (key === 'integrationChallenges') {
            return isLocaleDe() ? 'Integrationsherausforderungen' : 'Integration Challenges';
        }
        if (key === 'results') {
            return isLocaleDe() ? 'Ergebnisse' : 'Results';
        }
        if (key === 'readAbout') {
            return isLocaleDe() ? 'Lesen Sie mehr darüber' : 'Read about';
        }
        if (key === 'contactUs') {
            return isLocaleDe() ? 'Kontaktieren Sie uns' : 'contact us';
        }
        if (key === 'moreCases') {
            return isLocaleDe() ? 'Weitere Anwendungsfälle' : 'more cases';
        }
        return key;
    };

    const getLinkLang = () => isLocaleDe() ? '/de' : '';
    
    const getCustomerLinkHref = (customer) => {
        const lang = getLinkLang();
        const path = customer.node.slug !== null ? `customers/${customer.node.slug}` : `customers/${slug(customer.node.name)}`;
        return `${lang}/${path}`;
    };

    const getPlatformLinkHref = (platform) => {
        const lang = getLinkLang();
        return `${lang}/platform/${slug(platform)}`;
    };

    return (
        <Layout
            title={`${getTranslation('title')} - ${name}`}
            transparentNavigation={true}
            isDark={true}
            seoData={seoData}
            location={location}
            language={pageContext.locale}
        >
            <main id="content" style={{ marginTop: "-80px" }}>
                <section className={`hero is-fullheight ${color !== null ? `is-${color}-tertiary-bg` : "is-orange-tertiary-bg"}`}>
                    <div className="hero-body">
                        <div className="container is-hidden-mobile content">
                            <div className="columns">
                                <div className="column" style={{ alignSelf: "center" }}>
                                    <img src={getSrc(logo)} alt={logoAlt} width={adjustedLogoWidthAndHeight.width} height={adjustedLogoWidthAndHeight.height} className="margin-bottom-20" />
                                    <h1 className="title font-variable is-transparent text-stroke-black is-black size-60 margin-y-0">{name}</h1><br />
                                    <p className={`is-${color} font-variable lowercase font-width-variation-930 size-16`}>{category}</p>
                                    <p className="size-22 line-height-32 margin-bottom-30 is-black">{description}</p>
                                    {whitePaper && (
                                        <ButtonIconHover outerLink={true} to={whitePaper.file.url} color={color} customStyles={{ marginRight: "10px" }}>
                                            <div className="flex flex-vcenter">
                                                <img src={transcriptIcon} alt="" />
                                                <strong>
                                                    {getTranslation('whitePaper')}
                                                </strong>
                                            </div>
                                        </ButtonIconHover>
                                    )}
                                    {videoLink && (
                                        <ButtonIconHover outerLink={true} to={videoLink} color="grey-1">
                                            <div className="flex flex-vcenter">
                                                <img src={grey_play} alt="" />
                                                <strong>
                                                    {getTranslation('watchVideo')}
                                                </strong>
                                            </div>
                                        </ButtonIconHover>
                                    )}
                                </div>
                                <div className="column has-text-centered">
                                    <GatsbyImage image={getImage(heroImage)} alt={name} className="rounded-full" />
                                </div>
                            </div>
                        </div>
                        <div className="container is-hidden-tablet content">
                            <div className="columns is-mobile is-reversed-mobile">
                                <div className="column" style={{ alignSelf: "center" }}>
                                    <img src={getSrc(logo)} style={{ maxWidth: "200px" }} className="margin-bottom-20" />
                                    <h1 className="title font-variable is-transparent text-stroke-black is-black size-60 margin-y-0">{name}</h1><br />
                                    <p className={`is-${color} font-variable lowercase font-width-variation-930 size-16`}>{category}</p>
                                    <p className="size-24 is-black">{description}</p>
                                    <div className="columns is-gapless is-mobile">
                                        {whitePaper && (
                                            <div className="column is-6">
                                                <ButtonIconHover outerLink={true} to={whitePaper.file.url} color={color} customStyles={{ marginRight: "5px" }}>
                                                    <div className="flex flex-vcenter">
                                                        <img src={transcriptIcon} alt="" />
                                                        <strong className="size-18 padding-x-4">
                                                        {getTranslation('whitePaper')}
                                                        </strong>
                                                    </div>
                                                </ButtonIconHover>
                                            </div>
                                        )}
                                        {videoLink && (
                                            <div className="column is-6">
                                                <ButtonIconHover outerLink={true} to={videoLink} color="grey-1">
                                                    <div className="flex flex-vcenter">
                                                        <img src={grey_play} alt="" />
                                                        <strong className="size-18 padding-x-6">
                                                            {getTranslation('watchVideo')}
                                                        </strong>
                                                    </div>
                                                </ButtonIconHover>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="column has-text-centered">
                                    <GatsbyImage
                                        image={getImage(heroImage)}
                                        style={{ maxWidth: "100%" }}
                                        alt={name}
                                        className="rounded-full" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={`section ${color !== null ? `is-${color}-article-gradient` : "is-orange-article-gradient"}`}>
                    <div className="container customer-content content">
                        <div className="columns is-multiline">
                            <div className="column is-10 is-offset-1">
                                <div className="columns padding-y-50">
                                    <div className="column is-6">
                                        <p className="size-24 is-black">
                                            {getTranslation('itLandscape')}
                                        </p>
                                        {itLandscape.map((x, i) => (
                                            <div className="flex flex-vcenter" key={i} style={{ marginBottom: "5px" }}>
                                                <Link to={getPlatformLinkHref(x)} className={`font-regular is-grey-1 size-22 line-height-32 is-${color}-border-bottom`}>{x}</Link>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="column is-6">
                                        <p className="size-24 is-black">
                                            {getTranslation('integrationChallenges')}
                                        </p>
                                        {integrationPatterns.map((x, i) => (
                                            <div className="flex flex-vcenter" key={i} style={{ marginBottom: "5px" }}>
                                                <Link to={getPlatformLinkHref(x)} className={`font-regular is-grey-1 size-22 line-height-32 is-${color}-border-bottom`}>{x}</Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column">
                                        <h2 className="subtitle is-black size-40">
                                            {getTranslation('businessGoals')}
                                        </h2>
                                        <ContentHTMLWithHoverPopUps
                                            contentHTML={businessGoals.childMarkdownRemark.html} 
                                            popUpItems={definedTermsPopUpItems}
                                        />
                                    </div>
                                </div>
                                <div className="columns has-text-centered padding-y-50">
                                    <div className="column is-three-fifths is-offset-one-fifth">
                                        <h3 className={`blockquote is-${color} size-36 line-height-40`}>
                                            {quote}
                                        </h3>
                                        <p className="size-16">{quoteAuthor}</p>
                                    </div>
                                </div>
                            </div>
                            {integrationChallenges !== null && (
                                <div className="column is-10 is-offset-1">
                                    <div className="columns padding-y-50">
                                        <div className="column">
                                            <h2 className="subtitle is-black size-40">
                                                {getTranslation('integrationChallenges')}
                                            </h2>
                                            <ContentHTMLWithHoverPopUps
                                                contentHTML={integrationChallenges.childMarkdownRemark.html} 
                                                popUpItems={definedTermsPopUpItems}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {image !== null && image.fluid && (
                                <div className="column is-12 has-text-centered">
                                    <GatsbyImage
                                        image={getImage(image)}
                                        style={{ maxWidth: "100%" }}
                                        alt={image.title} />
                                </div>
                            )}
                            <div className="column is-10 is-offset-1">
                                <div className="columns padding-y-50">
                                    <div className="column">
                                        <h2 className="subtitle is-black size-40">
                                            {getTranslation('results')}
                                        </h2>
                                        <ContentHTMLWithHoverPopUps
                                            contentHTML={results.childMarkdownRemark.html} 
                                            popUpItems={definedTermsPopUpItems}
                                        />
                                        <ShareButtons
                                            url={location.href}
                                            title={`${getTranslation('readMore')} ${name}`}
                                            locale={pageContext.locale}
                                        />
                                        <Button
                                            customClass="margin-top-40"
                                            textClass="size-20"
                                            type="outlined"
                                            text={getTranslation('contactUs')}
                                            color="purple"
                                            to="/ask-us-anything"
                                            outerLink={isLocaleDe()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns is-centered">
                            {numbers.map((number, i) => (
                                <div className="column is-one-quarter" key={i}>
                                    <div style={{ position: "relative", textAlign: "center" }}>
                                        <img src={numbersIcons[i]} alt="" style={{ width: "100%" }} />
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)"
                                            }}
                                            className="content"
                                        >
                                            <h2 className={`title is-${color}`} style={{ wordBreak: 'keep-all' }}>
                                                {number}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                {pageLinkLists !== null && (
                    <section className={`section is-medium is-${color}-bg`}>
                        <div className="container content">
                            <div className="columns">
                                <div className="column is-three-fifths is-offset-one-fifth">
                                    <PageLinkList lists={pageLinkLists} header={pageLinkListHeader} locale={pageContext.locale}/>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {!isEmpty(moreCustomers) && (
                    <section className="section">
                        <div className="container is-hidden-mobile">
                            <div className="columns">
                                <div className="column is-10 is-offset-1">
                                    <h2 className="ingress">
                                        {getTranslation('moreCases')}
                                    </h2>
                                    {moreCustomers.map((customer, i) => (
                                        <React.Fragment key={i}>
                                            {i === 0 && <hr className="no-margin" />}
                                            <div className={`is-${getColor(i)}-customers-lift-hover-bg customers-lift`} key={i}>
                                                <Link to={getCustomerLinkHref(customer)}>
                                                    <div className="columns is-vcentered no-margin" style={{ padding: "0 10px" }}>
                                                        <div className="column is-4">
                                                            <h2 className="subtitle is-black size-28">{customer.node.name}</h2>
                                                        </div>
                                                        <div className="column customers-lift-category is-4 size-20 lowercase is-grey-1">
                                                            {customer.node.category}
                                                        </div>
                                                        <div className="column customers-lift-arrow is-4">
                                                            <div className={`is-${getColor(i)}-arrow-with-spacing arrow-container right-float`}>
                                                                <div className="line"></div>
                                                                <div className="point"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <hr className="no-margin" />
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="is-hidden-tablet">
                            <h2 className="ingress">
                                {getTranslation('moreCases')}
                            </h2>
                            {moreCustomers.map((customer, i) => (
                                <div className={`is-${getColor(i)}-customers-lift-hover-bg customers-lift`} key={i}>
                                    <Link to={getCustomerLinkHref(customer)}>
                                        <div className="columns is-mobile is-multiline is-vcentered no-margin" style={{ padding: "0 10px" }}>
                                            <div className="column is-10">
                                                <h2 className="is-black size-28">{customer.node.name}</h2>
                                                <span className="size-20 lowercase is-grey-1">{customer.node.category}</span>
                                            </div>
                                            <div className="column is-2">
                                                <div className={`is-${getColor(i)}-arrow-with-spacing right-float`}>
                                                    <div className="line"></div>
                                                    <div className="point"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </section>
                )}
            </main>
        </Layout>
    );
}

export const query = graphql`
query ($contentful_id: String!, $locale: String, $contentful_content_vocabulary_item_slugs: [String]) {
    site {
        siteMetadata {
            colors {
                green
                orange
                purple
                pink
                blue
            }
        }
    }
    contentfulCustomer(
        contentful_id: {eq: $contentful_id}
        node_locale: {eq: $locale}
    ) {
        results {
            childMarkdownRemark {
                html
            }
        }
        integrationChallenges {
            childMarkdownRemark {
                html
            }
        }
        businessGoals {
            childMarkdownRemark {
                html
            }
        }
        pageLinkListHeader
        pageLinkLists {
            linkList {
              ... on ContentfulWebinarV2 {
                __typename
                id
                title
                webinarUrl
                webinarFreeText {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                webinarImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulServicesSubpage {
                __typename
                id
                title
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulLanding {
                __typename
                id
                title
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulCustomerType {
                __typename
                id
                name
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(width: 400, height: 400)
                }
              }
              ... on ContentfulCustomer {
                __typename
                id
                name
                description
                slug
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulCoSellPartner {
                __typename
                id
                name
                slug
                slogan
              }
              ... on ContentfulArticle {
                __typename
                id
                title
                articleUrl
                subtitle
                heroImage {
                  gatsbyImageData(width: 400, height: 400)
                }
              }
            }
            title
          }
        metaTags
        category
        quoteAuthor
        quote
        numbers
        name
        heroImage {
            gatsbyImageData(width: 1024)
            file {
                url
            }
        }
        logo {
            gatsbyImageData
            title
            file {
                details {
                    image {
                        height
                        width
                    }
                }
            }
        }
        videoLink
        integrationPatterns
        itLandscape
        image {
            title
            gatsbyImageData(width: 1820)
        }
        linkedInThumbnail {
            file {
                url
            }
        }
        description
        color
    }
    allContentfulCustomer(sort: {order: DESC, fields: createdAt}, limit: 10, filter: {contentful_id: {ne: $contentful_id }, node_locale: {eq: $locale} }) {
        edges {
            node {
                name
                category
                slug
                isDeTranslated
            }
        }
    }
    allContentfulDefinedTerm(
        filter: {slug: {in: $contentful_content_vocabulary_item_slugs}, node_locale: {eq: $locale}}
        ) {
        nodes {
            slug
            title
            description {
                childMarkdownRemark {
                    html
                    excerpt
                }
            }
        }
      }
}
`
